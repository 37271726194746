<template>
    <div class="page-radius-config">
        this is page-radius-config
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
// .page-radius-config {}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {}
</style>